<template>
  <a-modal
    title="解除用户"
    :width="400"
    :visible="visible"
    @cancel="handleCancel"
    @update:visible="updateVisible"
    @ok="agreeOk()"
  >
    <a-row :gutter="16">
      <a-col :md="24" :sm="24" :xs="24" style="height: 20px">
        <div>是否解除该用户的英雄认证信息</div>
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
import setting from '@/config/setting'
import * as userRealApi from '@/api/sys/userReal'
export default {
  name: 'removeEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      uploadUrl: setting.uploadUrl
    }
  },
  methods: {
    handleCancel() {
      this.$emit('update:visible', false)
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    // 通过
    agreeOk() {
      console.log(this.data.state)
      const data = { heroInfoId: this.data.id }
      userRealApi
        .relieve(data)
        .then((res) => {
          if (res.code === 0) {
            console.log(data)
            this.$message.success(res.msg)
            this.updateVisible(false)
            this.$emit('done')
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.message)
        })

        .catch(() => {})
    }
  }
}
</script>
<style scoped>
</style>
