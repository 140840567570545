<template>
  <div class="ud-body">
    <!-- 搜索表单 -->
    <a-card :bordered="true">
      <a-form
        :model="where"
        :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="姓名">
              <a-input
                v-model:value.trim="where.name"
                placeholder="请输入姓名查询"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="身份证">
              <a-input
                v-model:value.trim="where.idcard"
                placeholder="请输入身份证查询"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="手机号码">
              <a-input
                v-model:value.trim="where.phone"
                placeholder="请输入手机号码查询"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table
        ref="table"
        row-key="id"
        :datasource="datasource"
        :columns="columns"
        v-model:selection="selection"
        :scroll="{ x: 'max-content' }"
        @change="onTableChange"
      >
        <template #toolbar>
          <a-space>
            <a-button type="primary" @click="openEdit()">
              <template #icon>
                <plus-outlined />
              </template>
              <span>新建</span>
            </a-button>
            <a-button type="primary" danger @click="removeBatch">
              <template #icon>
                <delete-outlined />
              </template>
              <span>删除</span>
            </a-button>
          </a-space>
        </template>
        <template #img="{ record }">
          <img :src="record.img" width="50" height="50" />
        </template>
        <template #action="{ record }">
          <a-space>
            <a @click="openEdit(record)">编辑</a>
            <a-divider type="vertical" v-if="record.userId > 0" />
            <a v-if="record.userId > 0" @click="relieveClick(record)">解除</a>
            <a-divider type="vertical" v-if="record.userId == 0" />
            <a-popconfirm
              title="确定要删除此项吗？"
              @confirm="remove(record)"
              v-if="record.userId == 0"
            >
              <a class="ud-text-danger">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </ud-pro-table>
    </a-card>
    <!-- 编辑 -->
    <InfoEdit v-model:visible="showEdit" :data="current" @done="reload" />
    <!-- 解除 -->
    <RemoveEdit v-model:visible="relieveEdit" :data="current" @done="reload" />
  </div>
</template>

<script>
import * as heroInfoApi from '@/api/jyyw/heroInfo'
import InfoEdit from './info-edit'
import RemoveEdit from './info-relieveEdit'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons-vue'
export default {
  components: {
    PlusOutlined,
    DeleteOutlined,
    InfoEdit,
    RemoveEdit
  },
  data() {
    return {
      // 表格列配置
      columns: [
        {
          title: '姓名',
          dataIndex: 'name'
        },
        {
          title: '身份证号码',
          dataIndex: 'idcard'
        },
        {
          title: '手机号码',
          dataIndex: 'phone'
        },
        {
          title: '地区',
          dataIndex: 'area'
        },
        {
          title: '等级',
          dataIndex: 'grade'
        },
        {
          title: '英雄日期',
          dataIndex: 'heroDate'
        },
        {
          title: '英雄卡号',
          dataIndex: 'heroNumber'
        },
        {
          title: '昵称',
          dataIndex: 'nickname'
        },
        {
          title: '账号',
          dataIndex: 'username'
        },
        {
          title: '操作',
          key: 'action',
          width: '150px',
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {},
      // 表格排序条件
      order: {},
      // 表格选中数据
      selection: [],
      // 是否显示编辑弹窗
      showEdit: false,
      // 是否显示解除弹窗
      relieveEdit: false,
      // 是否显示详情弹窗
      showDetail: false,
      // 当前编辑数据
      current: null,
      // 编辑表单提交状态
      editLoading: false,
      // 是否显示用户导入弹窗
      showImport: false,
      categoryList: []
    }
  },
  created() {},
  methods: {
    datasource(option, callback) {
      heroInfoApi
        .page({
          ...this.where,
          ...this.order,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 表格分页、排序、筛选改变回调 */
    onTableChange(pagination, filters, sorter) {
      if (sorter.order) {
        this.order.sort = sorter.field
        this.order.order = { ascend: 'asc', descend: 'desc' }[sorter.order]
      } else {
        this.order = {}
      }
      // this.reload()
    },
    /* 刷新表格 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.where = {}
      this.$nextTick(() => {
        this.reload()
      })
    },
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      heroInfoApi
        .deleteById(row.id)
        .then((res) => {
          hide()
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的项吗?',
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          const data = { data: this.selection.map((d) => d.id) }
          heroInfoApi
            .deleteBatch(data)
            .then((res) => {
              hide()
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.reload()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              hide()
              this.$message.error(e.message)
            })
        }
      })
    },
    /* 打开编辑弹窗 */
    openEdit(row) {
      this.current = row
      this.showEdit = true
    },
    // 打开解除弹窗
    relieveClick(row) {
      this.current = row
      this.relieveEdit = true
    }
  }
}
</script>

<style scoped>
</style>
